export const products = [
  {
    name: 'iPhone 13 Pro 128GB',
    description: 'Brand new, sealed iPhone 13 Pro shipped directly from Apple.',
    image: 'https://cdn.vox-cdn.com/thumbor/w9t5YbaSQs7-a4DaVb5q9ewggrI=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22863273/vpavic_210916_4760_0240.jpg',
    active: true,
    price: 0.02,
    maxTickets: 200,
    contractAddress: "0x537C4A3AF6A70454DFb6949Fbb5eD0063d770e11",
    ABI: '[{"constant":true,"inputs":[],"name":"creator","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"_qty","type":"uint256"}],"name":"joinraffle","outputs":[{"name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"constant":true,"inputs":[{"name":"","type":"uint256"}],"name":"participants","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"prizeDescritpion","outputs":[{"name":"","type":"string"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"ticketsSold","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"maxTickets","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"winner","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_maxTickets","type":"uint256"},{"name":"_prizeDescritpion","type":"string"}],"payable":true,"stateMutability":"payable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_length","type":"uint256"},{"indexed":false,"name":"_qty","type":"uint256"}],"name":"JoinEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_winner","type":"address"},{"indexed":false,"name":"_prizeDescritpion","type":"string"}],"name":"DrawEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_from","type":"address"},{"indexed":false,"name":"_value","type":"uint256"}],"name":"Paid","type":"event"}]'
  },
  {
    name: 'iPhone 13 Pro Max 128GB',
    description: 'Brand new, sealed iPhone 13 Pro Max shipped directly from Apple.',
    image: 'https://cdn.vox-cdn.com/thumbor/w9t5YbaSQs7-a4DaVb5q9ewggrI=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22863273/vpavic_210916_4760_0240.jpg',
    active: true,
    price: 0.02,
    maxTickets: 250,
    contractAddress: "0xD5480148f9C2021bC650D73bD14D4e1F382085B9",
    ABI: '[{"constant":true,"inputs":[],"name":"creator","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"_qty","type":"uint256"}],"name":"joinraffle","outputs":[{"name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"constant":true,"inputs":[{"name":"","type":"uint256"}],"name":"participants","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"prizeDescritpion","outputs":[{"name":"","type":"string"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"ticketsSold","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"maxTickets","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"winner","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_maxTickets","type":"uint256"},{"name":"_prizeDescritpion","type":"string"}],"payable":true,"stateMutability":"payable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_length","type":"uint256"},{"indexed":false,"name":"_qty","type":"uint256"}],"name":"JoinEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_winner","type":"address"},{"indexed":false,"name":"_prizeDescritpion","type":"string"}],"name":"DrawEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_from","type":"address"},{"indexed":false,"name":"_value","type":"uint256"}],"name":"Paid","type":"event"}]'
  },
  {
    name: 'MacBook Pro 2022 M1',
    description: 'Apple M1 Pro with 10-core CPU, 16-core GPU, 16-core Neural Engine.',
    image: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/apple-macbook-m1-sq-1610121786.jpg?resize=640:*',
    active: true,
    price: 0.02,
    maxTickets: 500,
    contractAddress: "0x72242d184bdB22926a9bd145338E11Ee7b231ba3",
    ABI: '[{"constant":true,"inputs":[],"name":"creator","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"_qty","type":"uint256"}],"name":"joinraffle","outputs":[{"name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"constant":true,"inputs":[{"name":"","type":"uint256"}],"name":"participants","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"prizeDescritpion","outputs":[{"name":"","type":"string"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"ticketsSold","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"maxTickets","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"winner","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_maxTickets","type":"uint256"},{"name":"_prizeDescritpion","type":"string"}],"payable":true,"stateMutability":"payable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_length","type":"uint256"},{"indexed":false,"name":"_qty","type":"uint256"}],"name":"JoinEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_winner","type":"address"},{"indexed":false,"name":"_prizeDescritpion","type":"string"}],"name":"DrawEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_from","type":"address"},{"indexed":false,"name":"_value","type":"uint256"}],"name":"Paid","type":"event"}]'
  },
  {
    name: 'Original Bored Ape',
    description: 'Follow us on twitter for raffle date.',
    image: 'https://lh3.googleusercontent.com/mR3DK98FGoGxse3Ue3u9MOt2fr1oPFmm342AL8B-INSvOj9X4cVqxgM4LziXihS1wRxFCxgFF0ITf1Q5M3Afr93rbypUFpjcYXu7_SU=s0',
    active: false,
    price: 0.02,
    maxTickets: 5000,
    contractAddress: "0x72242d184bdB22926a9bd145338E11Ee7b231ba3",
    ABI: '[{"constant":true,"inputs":[],"name":"creator","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":false,"inputs":[{"name":"_qty","type":"uint256"}],"name":"joinraffle","outputs":[{"name":"","type":"bool"}],"payable":true,"stateMutability":"payable","type":"function"},{"constant":true,"inputs":[{"name":"","type":"uint256"}],"name":"participants","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"prizeDescritpion","outputs":[{"name":"","type":"string"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"ticketsSold","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"maxTickets","outputs":[{"name":"","type":"uint256"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"winner","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_maxTickets","type":"uint256"},{"name":"_prizeDescritpion","type":"string"}],"payable":true,"stateMutability":"payable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_length","type":"uint256"},{"indexed":false,"name":"_qty","type":"uint256"}],"name":"JoinEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_winner","type":"address"},{"indexed":false,"name":"_prizeDescritpion","type":"string"}],"name":"DrawEvent","type":"event"},{"anonymous":false,"inputs":[{"indexed":false,"name":"_from","type":"address"},{"indexed":false,"name":"_value","type":"uint256"}],"name":"Paid","type":"event"}]'
  }
]