import { Product } from './Product'
import ProductC from './Product'
import { Heading, Text, Container } from '@chakra-ui/react'
import { SimpleGrid } from '@chakra-ui/react'


export default function ProductList(props: { products: Product[] }) {
  return (
    <Container maxW={'6xl'} pb="10">
      <Heading fontWeight={600} fontSize={['xl', '2xl']} mb="15px" lineHeight={'110%'} id="raffles">
        <Text as={'span'} color={'green.400'}>
          🎟️ - Active Raffles
        </Text>
      </Heading>
      <SimpleGrid minChildWidth="200px" pt="5" mb="10" m="0" p="0" spacing={2}>
        {
          props.products.map(p => {
            return <ProductC product={p} />
          })
        }
      </SimpleGrid>
    </Container>
  )
}