import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  chakra,
  useColorModeValue,
  VisuallyHidden,
  Image,
  Link,
  Center
} from '@chakra-ui/react'
import { FaTelegram, FaTwitter, FaDiscord } from 'react-icons/fa';
import logo from '../assets/logo.svg'
import { ReactNode } from 'react';

const SocialButton = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}

      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};


export default function Hero() {
  return (
    <Container maxW={'3xl'}>
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 6, md: 6 }}
        py={{ base: 10, md: 10 }}
      >
        <Heading fontWeight={600} fontSize={['2xl', '4xl']} lineHeight={'110%'}>
          <Center>
            <Box  >
              <Image width="150px" display={'center'} src={logo} />
            </Box>
          </Center>
          blockraffle.io
        </Heading>

        <Stack
          direction={'column'}
          spacing={3}
          align={'center'}
          alignSelf={'center'}
          position={'relative'}
        >
          <Stack
            direction={'row'}
            spacing={6}
            alignContent={'center'}
            pb="20px"
          >
            <Link href='https://twitter.com/blockraffle_io' isExternal>
              <SocialButton label={'Twitter'}>
                <FaTwitter />
              </SocialButton>
            </Link>
            <Link href='https://discord.gg/rPHRrssn' isExternal>
              <SocialButton label={'Discord'}>
                <FaDiscord />
              </SocialButton>
            </Link>
            <Link href='https://t.me/+mYC5HODSqbNhOTU0' isExternal>
              <SocialButton label={'Telegram'}>
                <FaTelegram />
              </SocialButton>
            </Link>
          </Stack>
          <Link href='https://bscscan.com/address/0x537C4A3AF6A70454DFb6949Fbb5eD0063d770e11' isExternal>
            <Button
              colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'}
              px={[3, 6]}
              _hover={{
                bg: 'green.500',
              }}
              fontSize={['10px', '13px']}
            >
              Contract: 0x87D105047f002b882824097f78181E6d014916A0
            </Button>
          </Link>
          <Text color={'gray.500'} fontSize={['12px', '16px']}>
            Our raffles are drawn on the Binance Smart Chain network using blockchain smart contract
            technology. This provides you will full transparency and trust. Raffle winners are 
            automatically selected by the smart contract when all tickets are sold.
          </Text>
          <Link href='./support'>
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Get Support
          </Button>
          </Link>
        </Stack>
      </Stack>
    </Container>
  )
}