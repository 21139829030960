import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  config:{
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});
