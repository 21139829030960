import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import "@fontsource/inter";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Mission from "./pages/Mission";
import Support from "./pages/Support";
import NoPage from "./pages/NoPage";


function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />
            <Route path="mission" element={<Mission />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
