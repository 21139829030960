import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image
} from '@chakra-ui/react';
import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/fa';
import { ReactNode } from 'react';
import logo from '../assets/logo.svg'

const SocialButton = ({
  children,
  label,
}: {
  children: ReactNode;
  label: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallCentered() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <Image width="100px" src={logo} />
        <Stack direction={'row'} spacing={6}>
          <Link href={'./'}>Home</Link>
          <Link href={'#raffles'}>Raffles</Link>
          <Link href={'./support'}>Support</Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>© 2022 blockraffle.io All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <Link href='https://twitter.com/blockraffle_io' isExternal>
              <SocialButton label={'Twitter'}>
                <FaTwitter />
              </SocialButton>
            </Link>
            <Link href='https://discord.gg/rPHRrssn' isExternal>
            <SocialButton label={'Discord'}>
              <FaDiscord />
            </SocialButton>
            </Link>
            <Link href='https://t.me/+mYC5HODSqbNhOTU0' isExternal>
            <SocialButton label={'Telegram'}>
              <FaTelegram />
            </SocialButton>
            </Link>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}