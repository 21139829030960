import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { useContractCall, useContractFunction } from "@usedapp/core";

export function useGetTicketsSold(contractAddress: string, ABI: string) {
  const [ticketsSold]: any =
    useContractCall({
      abi: new ethers.utils.Interface(ABI),
      address: contractAddress,
      method: "ticketsSold",
      args: [],
    }) ?? [];
  return ticketsSold;
}

export function useGetWinner(contractAddress: string, ABI: string) {
  const [winner]: any =
    useContractCall({
      abi: new ethers.utils.Interface(ABI),
      address: contractAddress,
      method: "winner",
      args: [],
    }) ?? [];
  console.log(winner);
  return winner;
}

export function useBuyTicket(contractAddress: string, ABI: string) {
  const contractInterface = new ethers.utils.Interface(ABI);
  const contract = new Contract(contractAddress, contractInterface);
  const { state, send } = useContractFunction(contract, "joinraffle", {});
  return { state, send };
}

export function useContractMethod(methodName: string, contractAddress: string, ABI: string) {
  const contractInterface = new ethers.utils.Interface(ABI);
  const contract = new Contract(contractAddress, contractInterface);
  const { state, send } = useContractFunction(contract, methodName, {});
  return { state, send };
}
