import { useState } from "react";
import {
  Flex,
  Box,
  Image,
  useColorModeValue,
  StackDivider,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Link
} from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import { useGetTicketsSold, useGetWinner, useContractMethod } from "../hooks";
import { utils } from "ethers";
import { useEthers } from "@usedapp/core";
export interface Product {
  name: string;
  description: string;
  image: string;
  active: boolean;
  price: number;
  maxTickets: number;
  contractAddress: string;
  ABI: string;
}

export default function ProductC(props: { product: Product }) {
  const { account } = useEthers();
  const ticketsSold = useGetTicketsSold(props.product.contractAddress, props.product.ABI);
  const winner = useGetWinner(props.product.contractAddress, props.product.ABI);

  const { send: setBuyTicket } = useContractMethod("joinraffle", props.product.contractAddress, props.product.ABI);
  const [input, setInput] = useState("1");
  const [alert, setAlert] = useState("");

  function handleBuyTicket() {
    if (account) {
      const _count = parseInt(input);
      if (_count) {
        setBuyTicket(_count, {
          value: utils.parseEther((0.02 * _count).toString()),
        });
      }
    } else {
      setAlert("error: wallet not connected. You must connect your Binance Smart Chain wallet to buy tickets")
    }

  }

  function handleInput(valueAsString: string, valueAsNumber: number) {
    setInput(valueAsString);
  }

  return (
    <Flex p={0} w="full" alignItems="center" m="0" justifyContent="center" >
      <Box
        bg={useColorModeValue('white', 'gray.800')}

        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
      >

        <Image
          src={props.product.image}
          alt={`Picture of ${props.product.name}`}
          roundedTop="lg"
        />

        <Box p="6">
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              fontSize="md"
              fontWeight="bold"
              as="h4"
              lineHeight="tight"
              textAlign="center"
              maxW="280"
              mb={3}
            >
              {props.product.name}
            </Box>
          </Flex>
          <Text
            fontSize="xs"
            fontWeight="bold"
            lineHeight="tight"
            maxWidth={"240px"}
            minH="50px"
            mb={3}
          >

            {props.product.description}
          </Text>
          <Flex justifyContent="space-between" alignContent="center">
            <VStack
              divider={<StackDivider borderColor="green.500" />}
              spacing={3}
              w="full"
              align="stretch"
            >
              <Box
                h="20px"
                fontSize="sm"
                color={useColorModeValue('gray.800', 'white')}
              >
                🎟️ Entries - {props.product.active ? (ticketsSold ? ticketsSold.toNumber() : 0) : 0} / {props.product.maxTickets}
              </Box>
              <Box
                h="20px"
                fontSize="sm"
                color={useColorModeValue('gray.800', 'white')}
                isTruncated
              >
                💰 Price - BNB {props.product.price}
              </Box>
              <Box
                h="20px"
                fontSize="sm"
                color={useColorModeValue('gray.800', 'white')}
                isTruncated
              >
                👑 Winner - {props.product.active ? (winner && winner !== "0x0000000000000000000000000000000000000000" ?
                  <Link href={'https://bscscan.com/address/' + winner}>{winner.substring(0, 5) + "..." + winner.substring(35, 44)}</Link> : "In progress...") : "Coming soon"}
              </Box>
              <Box
                h="20px"
                fontSize="sm"
                color={useColorModeValue('gray.800', 'white')}
                isTruncated
              >
                📝 Contract - {props.product.active ? <Link isExternal href={'https://bscscan.com/address/' + props.product.contractAddress}>{props.product.contractAddress.substring(0, 5) + "..." + props.product.contractAddress.substring(35, 44)}</Link> : "Coming soon"}
              </Box>
              <Box mt={1}>
                <NumberInput
                  mb={2}
                  min={1}
                  defaultValue={1}
                  onChange={handleInput}
                  clampValueOnBlur={false}
                >

                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>

                </NumberInput>
                <Button isFullWidth colorScheme="purple" onClick={handleBuyTicket} isDisabled={props.product.active ? false : true}>
                  {props.product.active ? 'Buy Ticket(s)' : 'Coming soon...'}
                </Button>
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="tight"
                  maxWidth={"240px"}
                  minH="50px"
                  mt={3}
                >
                  {account ? "" : alert}
                </Text>

              </Box>
            </VStack>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}