import { Button, Box, Text } from "@chakra-ui/react";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import WalletConnectProvider from '@walletconnect/web3-provider'

type Props = {
  handleOpenModal: any;
};

export default function ConnectButton({ handleOpenModal }: Props) {
  const { activateBrowserWallet, activate, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  async function handleConnectWallet() {
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
        infuraId: '62687d1a985d4508b2b7a24827551934',
      })
      await provider.enable()
      activate(provider)
    } catch (error) {
      console.error(error)
    }
  }

  function handleConnectBrowser() {
    activateBrowserWallet();
  }

  return account ? (
    <Box
      display="flex"
      alignItems="center"
      background="gray.700"
      borderRadius="xl"
      py="0"
    >
      <Box px="3">
        <Text color="white" fontSize="md">
          {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} BSC
        </Text>
      </Box>
      <Button
        onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
      >

        <Text color="white" fontSize="md" fontWeight="medium" mr="2">
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Text>
        <Identicon />
      </Button>
    </Box>
  ) : (
    <Box>
      <Button
        onClick={handleConnectBrowser}
        bg="green.800"
        mr="15px"
        color="white.300"
        fontSize="sm"
        fontWeight="medium"
        borderRadius="5"
        border="1px solid transparent"
        _hover={{
          borderColor: "green.700",
          color: "green.400",
        }}
        _active={{
          backgroundColor: "green.800",
          borderColor: "green.700",
        }}
      >
        MetaMask
      </Button>

      <Button
        onClick={handleConnectWallet}
        bg="green.800"
        color="white.300"
        fontSize="sm"
        fontWeight="medium"
        borderRadius="5"
        border="1px solid transparent"
        _hover={{
          borderColor: "green.700",
          color: "green.400",
        }}
        _active={{
          backgroundColor: "green.800",
          borderColor: "green.700",
        }}
      >
        WalletConnect
      </Button>
    </Box>
  );
}
